<template>
  <div class="videoAdComponents">
    <div class="videoAd">
      <span class="adTip">{{ $t("chatvideoAd") }}</span>
      <i class="el-icon-circle-close" @click="closeVideoAd"></i>
      <AliyunVideo
        :AliyunId="AdInfo.videoAd.url"
        v-if="AdInfo.videoAd.url"
        :notShowAd="true"
        @videoEnd="closeVideoAd"
        ref="aliVideo"
      ></AliyunVideo>
    </div>
  </div>
</template>

<script>
import AliyunVideo from "~/baseComponents/AliyunVideo";
export default {
  props: {
    AdInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: { AliyunVideo },
  methods: {
    closeVideoAd() {
      this.$refs.aliVideo.pauseVideo();
      this.$emit("videoAdEnd");
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 780px) {
  @import "./less/videoAd_pc.less";
}

@media screen and (max-width: 780px) {
  @import "./less/videoAd_mobile.less";
}
</style>