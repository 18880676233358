<template>
  <div class="videoChatComponents">
    <div
      class="videoPanel"
      :class="{
        equityModel: showModel == 'equity',
        defaultModel: showModel == 'default',
        smallVideoPanel: smallPanel,
        mediumVideoPanel: !smallPanel,
        isChatIng: otherStatus.online || selfStatus.online,
      }"
    >
      <div
        :class="selfAtTop ? 'smallWindow' : 'bigWindow'"
        v-show="startChatState"
      >
        <div class="closeOtherBtn"  v-if="showClearother&&otherStatus.online">
          <img
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/icons/clearOther.png"
            alt=""
            @click="clearOtherSDK"
          />
        </div>
        <div id="otherWindow" class="videoInner"></div>
        <div class="noVideoStatus otherNoVideo" v-if="!otherStatus.online">
          <img
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/icons/noVideo.png"
            alt=""
          />

          <p>{{ $t("waitJoin") }}</p>
        </div>
        <div
          class="noVideoStatus otherNoVideo"
          v-if="otherStatus.online && !otherStatus.video"
        >
          <img
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/noVideoAvatar.png"
            alt=""
          />

          <p>{{ chatBothInfo.otherInfo.name }}</p>
        </div>
        <div class="otherMediaStatus" v-if="otherStatus.online">
          <div>
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/otherMicOpen.png"
              alt=""
              v-if="otherStatus.audio"
            />
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/otherMicClose.png"
              alt=""
              v-else
            />
          </div>
          <div>
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/otherVideoOpen.png"
              alt=""
              v-if="otherStatus.video"
            />
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/otherVideoClose.png"
              alt=""
              v-else
            />
          </div>
        </div>
      </div>
      <div
        :class="selfAtTop ? 'bigWindow' : 'smallWindow'"
        v-show="selfStatus.online"
      >
        <div id="selfWindow" class="videoInner"></div>
        <div
          class="noVideoStatus selfNoVideo"
          v-if="selfStatus.online && !selfStatus.video"
        >
          <img
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/noVideoAvatar.png"
            alt=""
          />
          <p>{{ chatBothInfo.selfInfo.name }}</p>
        </div>
      </div>
      <div
        class="changeSite"
        v-if="otherStatus.online && selfStatus.online && showModel == 'default'"
      >
        <img
          @click="selfAtTop = !selfAtTop"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/icons/changeWindow.svg"
          alt=""
        />
      </div>
      <div class="controlBtns" v-show="selfStatus.online">
        <div class="micControl fl">
          <div v-if="selfStatus.audio">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/micOpen.png"
              alt=""
              :title="$t('microphone')"
              @click="stopSelfMedia('audio')"
            />
          </div>
          <div v-else>
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/micClose.png"
              alt=""
              :title="$t('microphone')"
              @click="startSelfMedia('audio')"
            />
          </div>
        </div>
        <div class="videoControl fl">
          <div v-if="selfStatus.video">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/videoOpen.png"
              alt=""
              :title="$t('video')"
              @click="stopSelfMedia('video')"
            />
          </div>
          <div v-else>
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/videoClose.png"
              alt=""
              :title="$t('video')"
              @click="startSelfMedia('video')"
            />
          </div>
        </div>
        <div class="fl">
          <el-divider direction="vertical"></el-divider>
        </div>
        <div class="fl panelModel">
          <img
            v-show="showModel == 'default'"
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/icons/fenpingfangshi.png"
            alt=""
            @click="changeShowModel('equity')"
          />
          <img
            v-show="showModel == 'equity'"
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/icons/fenpingfangshi_1.png"
            alt=""
            @click="changeShowModel('default')"
          />
        </div>
        <div class="fl">
          <el-divider direction="vertical"></el-divider>
        </div>
        <div class="closeControl fl">
          <div>
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/videoChatIcon/closeChat.png"
              alt=""
              :title="$t('closeMeeting')"
              @click="closeChatManual"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import basicChat from "~/basePlugins/basicVideoChat";
export default {
  props: {
    chatBothInfo: {
      type: Object,
      default: () => {
        return {
          selfInfo: {},
          otherInfo: {},
        };
      },
    },
    smallPanel: {
      type: Boolean,
      default: false,
    },
    channel_id: {
      type: String,
      default: "",
    },
    user_id: {
      type: Number,
      default: null,
    },
    chat_class: {
      type: String,
      default: "",
    },
    mediaType: {
      type: Object,
      default: () => {
        return {
          video: true,
          audio: true,
        };
      },
    },
    showClearother:{
      type:Boolean,
      default:false,
    }
  },
  data() {
    return {
      videoChatSDK: null,
      videoChatConfig: {
        channel_id: null,
        user_id: null,
        type: "1v1",
        auto_publish: true,
        mediaConfig: {
          video: true,
          audio: true,
        },
        doms: {
          selfWindowDom: "selfWindow",
          otherWindowDom: "otherWindow",
        },
        apis: {},
      },
      selfAtTop: false,
      showModel: "default",
      otherStatus: {
        online: false,
        video: false,
        audio: false,
        user_id: null,
      },
      selfStatus: {
        online: false,
        video: false,
        audio: false,
        user_id: null,
      },
      startChatState: false,
    };
  },
  mounted() {
    //this.initVideoChat();
  },
  methods: {
    initVideoChat() {
      this.videoChatConfig.channel_id = this.channel_id;
      this.videoChatConfig.user_id = this.user_id;
      this.videoChatConfig.mediaConfig = this.mediaType;
      this.startChatState = true;

      this.videoChatSDK = new basicChat(
        this.videoChatConfig,
        this.$store,
        this.$message,
        {
          joinChannelSuccess: this.joinChannelSuccess,
          userStatusChange: this.userStatusChange,
          userJoined: this.userJoined,
          userLeft: this.userLeft,
        }
      );
    },
    userStatusChange(result) {
      // this.otherStatus = result.otherStatus;
      // this.selfStatus = result.selfStatus;
      this.$set(this.otherStatus, "online", result.otherStatus.online);
      this.$set(this.otherStatus, "user_id", result.otherStatus.user_id);
      this.$set(this.otherStatus, "video", result.otherStatus.video);
      this.$set(this.otherStatus, "audio", result.otherStatus.audio);
      this.$set(this.selfStatus, "online", result.selfStatus.online);
      this.$set(this.selfStatus, "user_id", result.selfStatus.user_id);
      this.$set(this.selfStatus, "video", result.selfStatus.video);
      this.$set(this.selfStatus, "audio", result.selfStatus.audio);

      //this.$forceUpdate();
      this.$emit("userStatusChange", result);
    },
    clearOtherSDK() {
      this.$emit('clearOtherSDK')
    },
    joinChannelSuccess() {
      this.$emit("joinChannelSuccess");
    },
    userJoined(user) {
      this.$emit("userJoined", user.uid);
    },
    userLeft(user) {
      this.$emit("userLeft", user.uid);
    },
    changeShowModel(model) {
      //equity||default
      this.showModel = model;
    },
    async startChat() {
      this.joinBtnLoading = true;
      await this.videoChatSDK.createLocaleTrack();
      this.joinBtnLoading = false;
      this.$emit("localePublishSuccess");
    },
    closeSdk() {
      this.startChatState = false;
      this.videoChatSDK.quitSDK();
    },
    closeChatManual() {
      this.closeSdk();
      this.$emit("closeChatManual");
    },
    showStatus() {
      console.log(this.otherStatus);
      console.log(this.selfStatus);
    },
    stopSelfMedia(type) {
      //audio || video
      this.videoChatSDK.closeLocaleTrack(type);
    },
    startSelfMedia(type) {
      this.videoChatSDK.createLocaleTrack(type);
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 780px) {
  @import "./less/videoChat_pc.less";
}
@media screen and (max-width: 780px) {
  @import "./less/videoChat_mobile.less";
}
</style>