<template>
  <div class="headerAdComponents">
    <div class="leftAd fl padding10">
      <al-image :src="AdInfo.leftAd.url" fit="cover"></al-image>
    </div>
    <div class="rightAd fr padding10">
      <al-image :src="AdInfo.rightAd.url" fit="cover"></al-image>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    AdInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
        
    };
  },
  
  methods: {
   
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 780px) {
  @import "./less/headerAd_pc.less";
}
</style>