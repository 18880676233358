<template>
  <div class="cardInfoComponents">
    <div v-if="cardInfo.id">
      <div class="companyName textOverflow">
        {{ cardInfo.company | textFormat }}
      </div>
      <div class="avatarAndName clearfix">
        <div class="fl avatarImg">
          <al-image :src="cardInfo.avatar"></al-image>
        </div>
        <div class="fl nameText">
          <p class="name textOverflow">{{ cardInfo.name | textFormat }}</p>
          <p class="job_title textOverflow">
            {{ cardInfo.job_title | textFormat }}
          </p>
        </div>
      </div>
    </div>
    <div v-else class="waitJoinTip">
      <p>{{$t('waitJoin')}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    console.log(this.cardInfo);
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 780px) {
  @import "./less/cardInfo_pc.less";
}
@media screen and (max-width: 780px) {
  @import "./less/cardInfo_mobile.less";
}
</style>