<template>
  <div class="biuniquePage">
    <div class="meak" v-if="showCheck">
      <div class="checkDeviceComponents">
        <checkDevice ref="checkDevice" @enterInto="enterIntoChat"></checkDevice>
      </div>
    </div>
    <div class="mobileEnterPage tc" v-if="showMobileEnterPage && isMobile">
      <img :src="mobileImgList[mobileImg]" alt="" />
      <div class="btnWrap">
        <el-button @click="mobileEnter" type="primary" round>{{
          $t("clickToEnter")
        }}</el-button>
      </div>
    </div>
    <div class="content_area">
      <div class="AdHeader">
        <headerAd v-if="pageInit" :AdInfo="AdInfo"></headerAd>
        <div v-else class="adLocation">
          <div class="left fl padding10"></div>
          <div class="right fr padding10"></div>
        </div>
      </div>
      <div class="MainContent">
        <div class="videoAndWhiteContent padding10">
          <div class="videoAdLocation" v-if="!videoAdEnd">
            <videoAd
              v-if="pageInit"
              :AdInfo="AdInfo"
              @videoAdEnd="handleVideoAdEnd"
            ></videoAd>
          </div>
          <div class="mainPanelWindow" v-show="videoAdEnd">
            <div
              class="videoLocation"
              :class="{ videoAtRight: videoAtRight, AtTop: videoAtTop }"
            >
              <videoChat
                v-if="pageInit"
                ref="videoChat"
                :channel_id="channel_id"
                :user_id="user_id"
                :smallPanel="videoAtRight"
                :chat_class="chat_class"
                :chatBothInfo="chatBothInfo"
                :mediaType="videoMediaType"
                @closeChatManual="closeChatManual"
                @userJoined="userJoinedChat"
                @userLeft="userLeftChat"
                @userStatusChange="userStatusChange"
                @joinChannelSuccess="joinChannelSuccess"
                @clearOtherSDK="clearOtherSDK"
                :showClearother="seatsOwner"
              ></videoChat>
            </div>
            <div
              class="whiteLocation"
              :class="{ whiteAtRight: whiteAtRight, AtTop: whiteAtTop }"
            >
              <whiteBoard
                v-if="pageInit"
                :whiteInfo="whiteInfo"
                :has_docs="hasWhite"
                ref="whiteBoard"
                @docsUploadSuccess="docsUploadSuccess"
                @docsUploadBegin="docsUploadBegin"
                @docsClearSuccess="docsClearSuccess"
                @clearselfSDk="clearSelfSDK"
                :user_id="user_id"
              ></whiteBoard>
            </div>
            <div class="videoControl" v-if="pageInit">
              <div class="leftText fl">
                <span v-if="chatTimeStatus == 'before'">{{
                  $t("chatTimeBefore", { time: timeDiff })
                }}</span>
                <span v-if="chatTimeStatus == 'ing'">{{
                  $t("chatTimeAfter", { time: timeDiff })
                }}</span>
                <span v-if="chatTimeStatus == 'after'">
                  {{ $t("chatTimeDelay", { time: timeDiff }) }}
                </span>
                <span v-if="chatTimeStatus == 'end'">
                  {{ $t("chatTimeEnd") }}
                </span>
              </div>
              <div class="rightBtns fr">
                <fileUpload
                  :autoUpload="true"
                  :accept="'.doc,.docx,.pdf,.ppt,.pptx'"
                  ossPath="conferenceWhite/"
                  @uploadCompleteOSS="uploadCompleteWhiteDocs"
                  @startUploadOSS="startUploadWhiteDocs"
                  :isDisabled="fileBtnLoading"
                  class="fl"
                  v-if="chatTimeStatus == 'ing'"
                >
                  <el-button
                    type="primary"
                    size="small"
                    :loading="fileBtnLoading"
                    class="fileUploadBtn"
                    >{{ uploadBtnText }}</el-button
                  >
                </fileUpload>
                <el-button
                  type="primary"
                  size="small"
                  v-if="hasWhite && isDocOwner"
                  :loading="fileBtnLoading"
                  @click="pptPreviousStep"
                  >{{ $t("previousPage") }}</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  v-if="hasWhite && isDocOwner"
                  :loading="fileBtnLoading"
                  @click="pptNextStep"
                  >{{ $t("nextPage") }}</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  v-if="hasWhite && isDocOwner"
                  :loading="fileBtnLoading"
                  @click="clearWhite"
                  >{{ $t("clearDoc") }}</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="changeVWLocation"
                  v-if="hasWhite"
                  >{{ $t("Switching") }}</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="infoContent">
          <div
            class="rightVideoAndwhiteContent padding10"
            v-show="hasWhite && !isMobile"
          ></div>
          <div class="cardContent padding10">
            <cardInfo v-if="pageInit" :cardInfo="cardInfo"></cardInfo>
          </div>
          <div class="mobileBtnContent padding10" v-if="isMobile">
            <el-button size="small" @click="watchVideo">{{
              $t("seeVideoArea")
            }}</el-button>
            <el-button size="small" class="seeDocs" @click="watchDocs">{{
              $t("seeDocument")
            }}</el-button>
          </div>
          <div class="clearfix" v-if="isMobile"></div>
          <div
            class="chatContent padding10"
            :class="hasWhite ? 'bothDocsAndVideo' : ''"
          >
            <textChat
              :channel_id="channel_id"
              :user_id="user_id"
              :chat_class="chat_class"
              v-if="pageInit"
              :chatBothInfo="chatBothInfo"
              ref="textChat"
            ></textChat>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileUpload from "~/baseComponents/fileUpload";
import checkDevice from "~/baseComponents/checkDevice";
import headerAd from "./components/headerAd";
import videoAd from "./components/videoAd";
import videoChat from "./components/videoChat";
import textChat from "./components/textChat";
import whiteBoard from "./components/whiteBoard";
import cardInfo from "./components/cardInfo";
import {
  handleAdInfo,
  confirmUserInfo,
  confirmUserInfoFree,
  formatSeconds,
  confirmChatClass,
} from "./utils";
import { confirmMobileType } from "~/baseUtils/getLogInfo";
export default {
  mounted() {
    //this.$refs.checkDevice.stratCheck()
    //this.initVideoChat();
    //this.initPageParams();
    //this.handleIsCheck();
    this.checkCurrentEnv();
  },
  components: {
    checkDevice,
    videoChat,
    videoAd,
    textChat,
    whiteBoard,
    headerAd,
    fileUpload,
    cardInfo,
  },
  data() {
    return {
      joinBtnLoading: false,
      videoAtRight: false,
      whiteAtRight: false,
      pageInit: false,
      talkInfo: null,
      AdInfo: null,
      whiteInfo: null,
      cardInfo: null,
      chatBothInfo: null,
      fileBtnLoading: false,
      hasWhite: false,
      isDocOwner: false,
      uploadBtnText: this.$t("Uploaddocument"),
      chatTimeStatus: "ing",
      videoAdEnd: false,
      startTime: 0,
      endTime: 0,
      timeDiff: "",

      hasConfirmSdk: false,
      channel_id: "",
      user_id: 52048,
      chat_type: "",
      chat_class: "",
      showCheck: false,
      videoMediaType: {
        video: true,
        audio: true,
      },
      back_url: "",

      isMobile: false,
      showMobileEnterPage: false,
      videoAtTop: true,
      whiteAtTop: false,
      seatsOwner: false,
      mobileImg: "",
      mobileImgList: {
        default:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/logo_sign.jpg",
        ap: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/aopai/logo.png",
        zhy: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/zhy-applent/login_logo.png",
        tif: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tif-applent/img_log.png",
        hycxlm: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hycxlm-applets/logo.png"
      },
    };
  },
  methods: {
    checkCurrentEnv() {
      this.mobileInfo = confirmMobileType();
      this.isMobile = this.mobileInfo.isMobile;
      if (this.isMobile) {
        this.confirmMobileImg();
        this.videoAdEnd = true;
        this.showMobileEnterPage = true;
        document.addEventListener("gesturestart", function (event) {
          event.preventDefault();
        });
      } else {
        this.handleIsCheck();
      }
    },
    confirmMobileImg() {
      let routerParams = {};
      if (this.$route.query.parameter) {
        routerParams = this._decode(this.$route.query.parameter);
      }
      let mobileImg = routerParams.mobileImg || "default";
      this.mobileImg = mobileImg;
    },
    handleIsCheck() {
      this.showCheck = true;
      this.$nextTick(() => {
        this.$refs.checkDevice.stratCheck();
      });
    },
    mobileEnter() {
      this.showMobileEnterPage = false;
      this.initPageParams();
      this.reportLogMobile();
    },
    enterIntoChat(result) {
      console.log(result);
      this.videoMediaType = result;
      this.showCheck = false;
      this.initPageParams();
    },
    watchVideo() {
      this.videoAtTop = true;
      this.whiteAtTop = false;
    },
    watchDocs() {
      this.whiteAtTop = true;
      this.videoAtTop = false;
    },
    async initPageParams() {
      let routerParams = {};
      if (this.$route.query.parameter) {
        routerParams = this._decode(this.$route.query.parameter);
      }
      let channel_id = routerParams.channel_id || "1618408729258689";
      let user_id = routerParams.user_id || 53177;
      let chat_type = routerParams.chat_type || "seats"; //a_order 议程预约   d_order 日常预约   seats坐席   free自由聊天 miniBooth迷你站台
      let back_url = routerParams.back_url;

      this.channel_id = channel_id;
      this.user_id = parseInt(user_id);
      this.chat_type = chat_type;
      this.back_url = back_url;
      this.chat_class = confirmChatClass(chat_type);
      let talkInfo;
      if (this.chat_class == "free") {
        talkInfo = await this.$store.dispatch(
          "baseStore/getVideoChatInfoFree",
          {
            live_id: channel_id,
            isdaily: false,
            language: this.LOCALE == "en" ? 1 : 2,
            position: "1,2,3",
            platform: 1,
          }
        );
      } else {
        talkInfo = await this.$store.dispatch("baseStore/getVideoChatInfo", {
          live_id: channel_id,
          isdaily: chat_type == "a_order" ? false : true,
          language: this.LOCALE == "en" ? 1 : 2,
          position: "1,2,3",
          platform: 1,
        });
      }

      let info = talkInfo.data;
      this.startTime = info.start_time;
      this.endTime = info.end_time;
      this.talkInfo = Object.assign({}, talkInfo.data);

      if (this.chat_class == "free") {
        this.chatBothInfo = confirmUserInfoFree(
          info,
          this.user_id,
          this.LOCALE
        );
        this.seatsOwner = info.user1_id == this.user_id;
      } else {
        this.chatBothInfo = confirmUserInfo(info, this.user_id, this.LOCALE);
      }

      this.AdInfo = handleAdInfo(info, this.LOCALE);

      this.cardInfo = this.chatBothInfo.otherInfo;
      this.whiteInfo = {
        uuid: info.white_uuid,
        roomToken: info.white_token,
      };

      this.pageInit = true;
      if (!this.isMobile) {
        if (!this.AdInfo.videoAd.url) {
          this.videoAdEnd = true;
          this.confirmTime();
        }
      } else {
        this.confirmTime();
      }
    },
    confirmTime() {
      //this.startTime = 1600857942;
      //this.endTime = 1618390431;

      let isBetween = this.$moment().isBetween(
        this.$moment(this.startTime * 1000).add("-10", "m"),
        this.$moment(this.endTime * 1000).add("5", "m"),
        "seconds"
      );
      if (isBetween) {
        this.chatTimeStatus = "ing";
        this.confirmSdk();
      }
      let current = this.$moment();
      let startdiff = this.$moment(parseInt(this.startTime * 1000)).diff(
        current,
        "seconds"
      );
      let enddiff = this.$moment(parseInt(this.endTime * 1000)).diff(
        current,
        "seconds"
      );

      if (startdiff > 0) {
        //会议未开始
        this.chatTimeStatus = "before";
        let timer_a = setInterval(() => {
          let newCurrent = this.$moment();
          let newDiff = this.$moment(parseInt(this.startTime * 1000)).diff(
            newCurrent,
            "seconds"
          );
          if (newDiff == 600) {
            this.chatTimeStatus = "ing";
            this.confirmSdk();
          }
          this.timeDiff = formatSeconds(newDiff, this.LOCALE);
          if (newDiff <= 0) {
            this.confirmTime(); //开始时间倒计时完成，会议开始
            clearInterval(timer_a);
          }
        }, 1000);
      } else if (startdiff <= 0 && enddiff > 0) {
        //会议进行中
        this.chatTimeStatus = "ing";
        let timer_b = setInterval(() => {
          let newCurrent = this.$moment();
          let newDiff = this.$moment(parseInt(this.endTime * 1000)).diff(
            newCurrent,
            "seconds"
          );
          this.timeDiff = formatSeconds(newDiff, this.LOCALE);
          if (newDiff <= 0) {
            this.confirmTime(); //会议时间倒计时完成，会议结束
            clearInterval(timer_b);
          }
        }, 1000);
      } else if (enddiff <= 0) {
        //会议结束
        this.chatTimeStatus = "after";
        this.delayTime();
      }
    },
    delayTime() {
      let isBetween = this.$moment().isBetween(
        this.$moment(),
        this.$moment(this.endTime * 1000).add("5", "m"),
        "seconds"
      );
      if (isBetween) {
        let nowdiff = this.$moment(parseInt(this.endTime * 1000))
          .add("5", "m")
          .diff(this.$moment(), "seconds");
        let nowtimeTip = formatSeconds(nowdiff, this.LOCALE);
      }
      this.isDelay = true;
      let timer_c = setInterval(() => {
        let newCurrent = this.$moment();
        let newDiff = this.$moment(parseInt(this.endTime * 1000))
          .add("5", "m")
          .diff(newCurrent, "seconds");
        this.timeDiff = formatSeconds(newDiff, this.LOCALE);
        if (newDiff <= 0) {
          this.chatTimeStatus = "end";
          this.closeSDK(); //会议时间倒计时完成，会议结束
          clearInterval(timer_c);
        }
      }, 1000);
    },
    confirmSdk() {
      if (this.hasConfirmSdk) {
        return;
      }
      this.hasConfirmSdk = true;
      this.$nextTick(() => {
        this.handleLayout();
        this.$refs.videoChat.initVideoChat();
        this.$refs.textChat.initTextChat();
        this.$refs.whiteBoard.initWHITESDK();
      });
    },
    async clearWhiteLayout() {
      this.hasWhite = false;
      this.videoAtRight = false;
      this.whiteAtRight = false;
      if (this.isDocOwner) {
        let result = await this.$store.dispatch("baseStore/reportDocUpload", {
          live_id: this.talkInfo.live_id,
          has_docs: 0,
        });
        this.isDocOwner = false;
      }
    },

    async closeSDK() {
      this.chatTimeStatus = "end";
      this.clearWhiteLayout();
      if (!this.hasConfirmSdk) {
        return;
      }

      this.$refs.videoChat.closeSdk();
      this.$refs.textChat.closeSdk();
    },
    async closeChatManual() {
      this.chatTimeStatus = "end";
      this.$refs.textChat.closeSdk();
      this.clearWhiteLayout();
      if (this.back_url) {
        window.location.href = this.back_url;
      }
    },
    startChat() {
      (this.joinBtnLoading = true), this.$refs.videoChat.startChat();
    },
    handleVideoAdEnd() {
      this.videoAdEnd = true;
      this.confirmTime();
    },
    handleLayout() {
      if (this.talkInfo.has_docs) {
        this.hasWhite = true;
        this.videoAtRight = true;
        this.whiteAtRight = false;
        if (this.talkInfo.has_docs == this.user_id) {
          this.isDocOwner = true;
        }
      }
    },
    changeVWLocation() {
      this.videoAtRight = !this.videoAtRight;
      this.whiteAtRight = !this.whiteAtRight;
      this.$refs.whiteBoard.handleWhiteFit();
    },
    clearSelfSDK() {
      this.closeSDK();
      if (this.back_url) {
        window.location.href = this.back_url;
      }
    },
    clearOtherSDK() {
      this.$refs.whiteBoard.clearOtherSDk();
      this.updataChatUserInfo();
    },
    clearWhite() {
      this.$refs.whiteBoard.clearDocs();
    },
    startUploadWhiteDocs() {
      this.$refs.whiteBoard.handleBeginUpload();
    },
    uploadCompleteWhiteDocs(result) {
      this.$refs.whiteBoard.handleCompleteUpload(result);
    },
    pptPreviousStep() {
      this.$refs.whiteBoard.pptPreviousStep();
    },
    pptNextStep() {
      this.$refs.whiteBoard.pptNextStep();
    },
    docsUploadSuccess(user_id) {
      this.fileBtnLoading = false;
      this.videoAtRight = true;
      this.hasWhite = true;
      this.whiteAtRight = false;
      this.uploadBtnText = this.$t("Uploaddocument");
      if (user_id == this.user_id) {
        this.isDocOwner = true;
        this.$store.dispatch("baseStore/reportDocUpload", {
          live_id: this.talkInfo.live_id,
          has_docs: parseInt(this.user_id),
        });
      } else {
        this.isDocOwner = false;
      }
      this.$refs.whiteBoard.handleWhiteFit();
    },
    docsUploadBegin(user_id) {
      this.fileBtnLoading = true;
      if (user_id != this.user_id) {
        this.uploadBtnText = this.$t("EachUploaddocument");
      } else {
        this.uploadBtnText = this.$t("uploading");
      }
    },
    async docsClearSuccess() {
      this.videoAtRight = false;
      this.whiteAtRight = false;
      this.hasWhite = false;
      if (this.isDocOwner) {
        let result = await this.$store.dispatch("baseStore/reportDocUpload", {
          live_id: this.talkInfo.live_id,
          has_docs: 0,
        });
        this.isDocOwner = false;
      }
    },
    joinChannelSuccess() {
      if (this.chat_class == "free") {
        this.updataChatUserInfo();
      }
    },
    userJoinedChat(user_id) {
      if (this.chat_class == "free") {
        this.updataChatUserInfo();
      }
    },
    async userLeftChat(user_id) {
      if (!this.isDocOwner) {
        this.hasWhite = false;
        this.videoAtRight = false;
        this.whiteAtRight = false;
        let result = await this.$store.dispatch("baseStore/reportDocUpload", {
          live_id: this.talkInfo.live_id,
          has_docs: 0,
        });
      }
      if (this.chat_class == "free") {
        setTimeout(() => {
          this.updataChatUserInfo();
          this.$refs.textChat.clearTextMsgList();
        }, 5000);
      }
    },
    userStatusChange(result) {},
    updataChatUserInfo() {
      setTimeout(async () => {
        let talkInfo = await this.$store.dispatch(
          "baseStore/getVideoChatInfoFree",
          {
            live_id: this.channel_id,
          }
        );
        let info = talkInfo.data;
        this.chatBothInfo = confirmUserInfoFree(
          info,
          this.user_id,
          this.LOCALE
        );
        this.cardInfo = this.chatBothInfo.otherInfo;
      }, 1500);
    },
    reportLogMobile() {
      let params = {
        isBrowersuppot: true,
        os: confirmMobileType().isIos ? "IOS" : "Android",
        browser: "Mobile",
        video: "Mobile Video",
        audio: "Mobile Audio",
      };
      this.$store.dispatch("baseStore/addchatLog", {
        live_id: this.channel_id,
        user_id: this.user_id,
        agenda_type: this.chat_class == "free" ? 2 : 1,
        data_info: JSON.stringify({
          1: params.video ? params.video : "",
          2: params.audio ? params.audio : "",
          3: "True",
          4: params.os,
          5: params.browser,
          6: this.$moment().unix(),
        }),
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 780px) {
  @import "./pc.less";
}
@media screen and (max-width: 780px) {
  @import "./mobile.less";
}
@media screen and (max-height: 768px) {
  .biuniquePage {
    height: auto;
    padding: 20px 0px 20px;
    .content_area {
      height: 710px;
      position: static;
      transform: translate(0, 0);
    }
  }
}
</style>