<template>
  <div class="textChatComponents">
    <div class="chatInner" id="chatInner" v-loading="historyMsgLoading">
      <div class="chatItemContent">
        <div
          class="item clearfix"
          v-for="(item, index) in textMsgList"
          :key="index"
          :class="{ other: item.other, self: item.self }"
        >
          <div class="avatar">
            <img v-if="item.self" :src="chatBothInfo.selfInfo.avatar" alt />
            <img v-else :src="chatBothInfo.otherInfo.avatar" alt />
          </div>
          <div class="textInner">
            <div class="square"></div>
            <p>{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="editText" v-show="showInput">
      <el-input
        type="input"
        v-model="messageText"
        :placeholder="$t('enterHere')"
        resize="none"
        @keyup.enter.native="sendMessage"
      >
        <el-button slot="append" type="primary" @click="sendMessage">{{
          $t("send")
        }}</el-button>
      </el-input>
    </div>
  </div>
</template>

<script>
import basicTextChat from "~/basePlugins/basicTextChat";
export default {
  props: {
    chatBothInfo: {
      type: Object,
      default: () => {
        return {
          selfInfo: {},
          otherInfo: {},
        };
      },
    },
    channel_id: {
      type: String,
      default: "",
    },
    user_id: {
      type: Number,
      default: null,
    },
    chat_class: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      textChatSDK: null,
      textChatConfig: {
        user_id: null,
        channel_id: null,
        getHistory: true,
      },
      historyMsgLoading: false,
      textMsgList: [],
      messageText: "",
      showInput: true,
    };
  },
  mounted() {
    //this.initTextChat();
  },
  methods: {
    initTextChat() {
      this.textChatConfig.user_id = this.user_id;
      this.textChatConfig.channel_id = this.channel_id;
      this.textChatConfig.getHistory = this.chat_class == "free" ? false : true;
      this.textChatSDK = new basicTextChat(
        this.textChatConfig,
        this.$store,
        this.$message,
        {
          reviceMessage: this.reviceMessage,
          sendMessageComplete: this.sendMessageComplete,
          historyMessageComplete: this.historyMessageComplete,
        }
      );
    },
    reviceMessage(msg) {
      this.addChatItem(msg);
    },
    sendMessage(e) {
      e.preventDefault();
      if (this.messageText.trim() == "") {
        return;
      }
      this.textChatSDK.sendRTMMessage(this.messageText);
    },
    sendMessageComplete(text) {
      this.addChatItem(text, true);
      this.messageText = "";
    },
    historyMessageComplete(list) {
      this.textMsgList = list;
      this.handleScrollHeight();
    },
    addChatItem(text, isSelf) {
      this.textMsgList.push({
        text,
        self: isSelf,
        other: !isSelf,
      });
      this.handleScrollHeight();
    },
    handleScrollHeight() {
      this.$nextTick(() => {
        let div = document.getElementById("chatInner");
        div.scrollTop = div.scrollHeight;
      });
    },
    clearTextMsgList(){
      this.textMsgList = []
    },
    closeSdk() {
      this.showInput = false;
      this.textChatSDK.quitSdk();
    },
  },
};
</script>

<style scoped lang="less">
@media screen and (min-width: 780px) {
  @import "./less/textChat_pc.less";
}
@media screen and (max-width: 780px) {
  @import "./less/textChat_mobile.less";
}
</style>